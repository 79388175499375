import * as React from "react";
import './AppsPage.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import {useTitle} from "../../hooks/useTitle";
import cleverAiLogoImage from '../../assets/images/clever-ai-logo.png';


type OwnProps = {};

type Props = OwnProps;

const AppsPage: React.FC<Props> = props => {
    const {} = props;
    useTitle("Neurolify - Apps");
    return (
        <Container fluid className="CleverAiPolicyPage">
            <Row className={'p-5'}>
                <Col>
                    <h1>Apps</h1>
                </Col>
            </Row>
            <Row className={'p-5'}>
                <Col>
                    <Button href={'https://cleverai.app'} className={'appButton'}>
                        <div>
                            <img className='cleverAiLogoImage' src={cleverAiLogoImage} alt={'clever-ai-logo'}/>
                        </div>
                        <div className={'p-2'}>
                            Clever AI
                        </div>
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};


export default AppsPage;
